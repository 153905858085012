<template>
  <div id="wx_qrcode"></div>
</template>

<script>
export default {
  mounted () {
    window.WwLogin({
      id: 'wx_qrcode', // 登录页面显示二维码的容器id
      appid: 'ww14ea3e754e4d6d0c', // 企业微信的CorpID，在企业微信管理端查看
      agentid: 1000011, // 授权方的网页应用id，在具体的网页应用中查看
      // redirect_uri: encodeURIComponent('http://admin-dataview.bjstarfish.com'), // 重定向的地址，需要进行encode
      redirect_uri: encodeURIComponent(document.location.protocol + '//admin-dataview.bjstarfish.com/#/getcode'),
      state: ''
    })
  }
}
</script>

<style lang="less" scoped>
#wx_qrcode {
  width: 100%;
  height: 100vh;
  background-color: #535252;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
